import React from 'react'
import SbEditable from 'storyblok-react'
import {
  ContentContainer,
  BackgroundImage,
  H3,
  MarkdownHandler,
  Img,
} from '@system'
import { Box, useMediaQuery, makeStyles } from '@material-ui/core'
import ACC_THEME from '@themes'
import get from 'lodash/get'
import renderBloks from '@renderBloks'

const useStyles = makeStyles((theme) => ({
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backgroundImage: {
    width: '100%',
    minHeight: '175px',
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'top left !important',
    [theme.breakpoints.down('sm')]: {
      '&:before': {
        width: '100%',
      },
    },
  },
  description: {
    width: '65%',
    color: theme.palette.common.white,
    padding: '0px 10px',
  },
  outerContainer: {
    margin: '20px 30px',
    marginLeft: '45px',
  },
  button: {
    alignSelf: 'center',
  },
  container: {
    margin: '20px 0px',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const BannerPanel = (props) => {
  const { backgroundImage, topLogo, title, description, cta, links } =
    props.blok

  const classes = useStyles()
  const isNavigationItem = !!props.isNavigationItem

  const background = backgroundImage && get(backgroundImage, '[0].image')

  const ContentWrapper = (props) => {
    return isNavigationItem ? (
      <Box className={classes.outerContainer}>{props.children}</Box>
    ) : (
      <ContentContainer className={classes.container}>
        {props.children}
      </ContentContainer>
    )
  }

  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable content={props.blok}>
      {isNavigationItem && handHeldDevice ? (
        links && links.length > 0 && renderBloks(links)
      ) : (
        <BackgroundImage className={classes.backgroundImage} image={background}>
          <ContentWrapper>
            {topLogo && (
              <Img
                src={topLogo}
                style={{
                  alignSelf: 'flex-start',
                  maxHeight: '80px',
                  width: '250px !important',
                  maxWidth: '16vw',
                  margin: '10px',
                  top: '10px',
                  left: '37px',
                }}
              />
            )}
            {title && <H3 className={classes.title}>{title}</H3>}
            <Box className={classes.flexBox}>
              {description && (
                <MarkdownHandler className={classes.description}>
                  {description}
                </MarkdownHandler>
              )}
              {cta && cta.length > 0 && (
                <Box className={classes.button}>{renderBloks(cta)}</Box>
              )}
            </Box>
          </ContentWrapper>
        </BackgroundImage>
      )}
    </SbEditable>
  )
}

export default BannerPanel
